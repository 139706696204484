import * as React from "react";

import Layout from "../components/layout";
import { SEOv2 } from "../components/seov2";

const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;

export const Head = () => <SEOv2 title="404: Not found" />;
